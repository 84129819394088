import { DATE_TIME_SEO_FORMAT, EVENT_PLACEHOLDER_IMAGE } from 'consts'

import { formatDateTime } from './DateTime'
import isObjectEmpty from './Objects'
import { htmlToPlainString, trimString } from './String'

export const getSeoObjectForEvent = event => {
  return {
    src: '[eventId]',
    title: event?.name ? `${event?.name} | Sessami` : 'Sessami',
    description: trimString(htmlToPlainString(event?.description), 360, false),
    imageURL: event?.images?.[0]?.url || event?.image || EVENT_PLACEHOLDER_IMAGE,
    url: `${process.env.NEXT_PUBLIC_APP_URL}/events/${event.eventId}`,
    keywords: event?.tags?.length ? event?.tags?.map(i => i.value).join(', ') : '',
  }
}

export const getSeoObjectForBlog = blog => {
  const prop = blog?.properties || {}
  if (isObjectEmpty(prop)) return {}

  const title = prop?.Name?.title?.[0]?.text?.content
  const description = prop?.ShortDescription?.rich_text?.[0]?.text?.content
  const imageURL = prop?.HeaderImage?.files?.[0]?.file?.url
  const url = `${process.env.NEXT_PUBLIC_APP_URL}/blogs/${blog.id}`
  // const keywords = blog?.tags?.length ? blog?.tags?.map(i => i.value).join(', ') : ''

  const datePublished = formatDateTime(new Date(blog?.created_time), DATE_TIME_SEO_FORMAT)
  const dateModified = formatDateTime(new Date(blog?.last_edited_time), DATE_TIME_SEO_FORMAT)

  const author = [
    {
      '@type': 'Organization',
      name: 'Sessami',
      url: 'https://sessami.com',
    },
  ]

  const seo = {
    src: '[blogId]',
    title: title ? `${title} | Sessami Blog` : 'Sessami Blog',
    description: trimString(description, 360, false),
    imageURL: imageURL || EVENT_PLACEHOLDER_IMAGE,
    images: [imageURL || EVENT_PLACEHOLDER_IMAGE],
    url,
    // keywords,
    datePublished,
    dateModified,
    author,
  }

  return seo
}

export const getSeoObjectForHost = host => {
  const title = host?.hostTitle
  const address = host?.venues?.[0]?.formattedAddress
  const bio = host?.bio
  const profileImage = host?.profileImage

  return {
    src: '[hostId]',
    title: title ? `${title}${address && ` | ${address}`} | Sessami` : 'Sessami',
    description:
      bio ||
      'A dedicated event organizer bringing you unforgettable experiences. Discover and join events on Sessami.',
    image: profileImage || EVENT_PLACEHOLDER_IMAGE,
    url: `${process.env.NEXT_PUBLIC_APP_URL}/hosts/${host.id}`,
    keywords: `sessami, events, concerts, festivals, workshops, conferences, local events, tickets, ${title || 'event organizer'}`,
  }
}
