import { APP_UI_PLATFORMS, APP_UI_ROUTES, ROUTES } from 'consts'
import useCoordsFetcher from 'hooks/useCoordsFetcher'
import useFetcherForUser from 'hooks/useFetcherForUser'
import useLogIn from 'hooks/useLogIn'
import { useRouter } from 'next/router'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAppUI, setPlatform } from 'store/reducers'
import getPlatform from 'utils/Platform'

import AuthUserLayout from './AuthUserLayout'
import DefaultLayout from './DefaultLayout'
import ZenLayout from './ZenLayout'

const TemplateProvider = ({ children }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const isMounted = useRef(false)

  const { fetchCoords } = useCoordsFetcher()
  const { fetchAllPublicEvents } = useFetcherForUser()
  const { fetchUserInfo } = useLogIn()

  const accessToken = useSelector(state => state?.pr?.user?.auth?.stsTokenManager?.accessToken)
  const platform = useSelector(state => state?.pr?.ui?.platform)
  const isZen = router.query?.zen

  // Fetch common data for all user types (user/host) here
  useEffect(() => {
    // Only show location prompt on explore pages
    if (router.asPath.includes(ROUTES.EXPLORE)) fetchCoords()
    fetchAllPublicEvents()
  }, [])

  // Fetch user data when accessToken is available
  useEffect(() => {
    if (!isMounted.current || !accessToken) return

    // Fetch user data here
    fetchUserInfo()
  }, [accessToken]) // Only run when accessToken changes

  // Set isMounted to true after the first render
  useEffect(() => {
    isMounted.current = true
  }, [])

  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [router.asPath])

  useEffect(() => {
    if (!platform) dispatch(setPlatform(getPlatform()))
    const isAppUi = APP_UI_PLATFORMS.includes(platform) && APP_UI_ROUTES.includes(router.pathname)
    dispatch(setAppUI(isAppUi))
  }, [platform])

  if (accessToken) {
    return <AuthUserLayout>{children}</AuthUserLayout>
  } else if (isZen) {
    return <ZenLayout>{children}</ZenLayout>
  } else {
    return <DefaultLayout>{children}</DefaultLayout>
  }
}

export default TemplateProvider
