import { SEO_DEFAULTS } from 'consts/Seo'
import Head from 'next/head'

const Seo = ({ override }) => {
  // console.info('@Seo', override?.src, override)

  // Basic Information
  const title = override?.title || SEO_DEFAULTS.displayTitle
  const description = override?.description || SEO_DEFAULTS.description
  const url =
    override?.url || (typeof window !== 'undefined' ? window.location.href : SEO_DEFAULTS.url)
  const imageURL =
    override?.imageURL ||
    (override?.image ? process.env.NEXT_PUBLIC_APP_URL + override?.image : SEO_DEFAULTS.imageURL)
  const keywords = SEO_DEFAULTS.keywords + (override?.keywords ?? '')

  // OpenGraph
  // const ogImageWidth = override?.opengraphImage?.mediaDetails?.width
  // const ogImageHeight = override?.opengraphImage?.mediaDetails?.height
  const twitterCardType = 'summary_large_image'

  // Events

  const followStatus = override?.followStatus ?? SEO_DEFAULTS.followStatus
  const indexStatus = override?.indexStatus ?? SEO_DEFAULTS.indexStatus

  // Bots and crawlers
  const robotsIndexing =
    (followStatus ? 'index' : 'noindex') + ', ' + (indexStatus ? 'follow' : 'nofollow')

  return (
    <Head>
      {/* OPEN GRAPH PROTOCOL TAG REFERENCES - https://ogp.me */}
      {/* HTML Meta Tags*/}
      <title key='page-title'>{title}</title>

      {/* Basic Metadata Tags*/}
      <meta
        name='comment'
        content='---------------------- META TAGS BEGIN ----------------------'
      />
      <meta name='title' content={title} key='meta-title' />
      <meta name='description' content={description} key='meta-description' />
      <meta name='keywords' content={keywords} key='meta-keywords' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />

      <meta
        name='comment'
        content='---------------------- OGP Tags for Social Medias ----------------------'
      />
      <meta property='og:title' content={title} key='og:title' />
      <meta property='og:type' content='website' key='og:type' />
      <meta property='og:image' content={imageURL} key='og:image' />
      <meta property='og:site_name' content={title} key='og:site_name' />
      <meta property='og:url' content={url} key='og:url' />
      <meta property='og:description' content={description} key='og:description' />

      {/* Events */}

      {/* Articles Meta Tags */}
      {/* <meta name='comment' content='---------------------- Article Tags ----------------------' />
      {author && <meta property='article:author' content={author} />}
      {publishedDate && <meta property='article:published_time' content={publishedDate} />}
      {opengraphPublisher && <meta property='article:publisher' content={opengraphPublisher} />}
      {opengraphModifiedTime && (
        <meta property='article:modified_time' content={opengraphModifiedTime} />
      )} */}

      {/* Twitter Meta Tags */}
      <meta
        name='comment'
        content='---------------------- Twitter Specific Tags ----------------------'
      />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      <meta property='twitter:image' content={imageURL} />
      <meta property='twitter:card' content={twitterCardType} />
      <meta property='twitter:url' content={url} />

      {/* Tags for Bots & Crawlers */}
      <meta
        name='comment'
        content='---------------------- Bots & Crawler Tags ----------------------'
      />
      {/* https://developers.google.com/search/docs/advanced/robots/robots_meta_tag */}
      <meta name='robots' content={robotsIndexing} />
      <meta name='googlebot' content={robotsIndexing} />
      {/* <meta name='AdsBot-Google' content={noIndexStatus} /> */}
      {/* <meta name='googlebot-news' content={noIndexStatus} /> */}

      <meta name='comment' content='---------------------- META TAGS END ----------------------' />
    </Head>
  )
}

export default Seo
