import ProgressBar from '@badrap/bar-of-progress'
import ConfirmationDialog from 'components/molecules/ConfirmationDialog'
import GridOverlay from 'components/molecules/GridOverlay'
import LoadingOverlay from 'components/molecules/LoadingOverlay'
import SkipToMainContent from 'components/molecules/skipToMainContent/SkipToMainContent'
import Analytics from 'components/nonUI/Analytics'
import GlobalMeta from 'components/nonUI/GlobalMeta'
import ReduxProvider from 'components/nonUI/ReduxProvider'
import Seo from 'components/nonUI/Seo'
import SeoBlog from 'components/nonUI/SeoBlog'
import SeoEvent from 'components/nonUI/SeoEvents'
import Style from 'components/nonUI/Style'
import AlertBanner from 'components/organisms/AlertBanner'
import Cookie from 'components/organisms/Cookie/Cookie'
import Debug from 'components/organisms/debug/Debug'
import TemplateProvider from 'components/templates/TemplateProvider'
import { Router } from 'next/router'
import React from 'react'
import 'styles/index.scss'
import 'utils/console'

const progress = new ProgressBar({
  size: 3,
  color: '#E8336A',
  className: 'z-50',
  delay: 0,
})

Router.events.on('routeChangeStart', progress.start)
Router.events.on('routeChangeComplete', progress.finish)
Router.events.on('routeChangeError', progress.finish)

const App = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout || (page => page)

  console.log('PR Number #14')

  return (
    <>
      {/* HEAD - META INFORMATION  & TRACKERS */}
      <GlobalMeta />
      <Seo override={pageProps?.data?.seo} />
      <SeoEvent event={pageProps?.data?.currentEvent} />
      <SeoBlog rawBlog={pageProps?.data?.blog?.[0]} />
      <Analytics />
      <Style />

      <ReduxProvider pageProps={pageProps}>
        <div
          id='main-content'
          className='flex min-h-screen flex-shrink-0 flex-grow flex-col overflow-hidden'>
          <LoadingOverlay />
          <ConfirmationDialog />
          <AlertBanner />

          <Debug />
          <GridOverlay />

          <SkipToMainContent />
          <Cookie />
          <TemplateProvider>{getLayout(<Component {...pageProps} />)}</TemplateProvider>
        </div>
      </ReduxProvider>
    </>
  )
}
export default App
