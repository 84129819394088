/* eslint-disable-next-line */
import { Figtree, Plus_Jakarta_Sans } from 'next/font/google'
import { Slide, ToastContainer } from 'react-toastify'

const figtree = Figtree({
  subsets: ['latin'],
  variable: '--font-figtree',
  display: 'swap',
})

const plusJakartaSans = Plus_Jakarta_Sans({
  subsets: ['latin'],
  variable: '--font-plusJakartaSans',
  display: 'swap',
})

const Style = () => {
  return (
    <>
      {/* Using next/font */}
      {/* Note: ClassName Approach is not working */}
      {/* https://nextjs.org/docs/pages/building-your-application/optimizing/fonts */}

      <style jsx global>{`
        html {
          font-family: ${figtree.style.fontFamily};
        }
        h1,
        h1 *,
        h2,
        h2 *,
        h3,
        h3 *,
        h4,
        h4 *,
        h5,
        h5 *,
        h6,
        h6 * {
          font-family: ${plusJakartaSans.style.fontFamily};
        }
        .figtree-toast-container {
          font-family: ${figtree.style.fontFamily};
        }
      `}</style>

      <ToastContainer
        transition={Slide} // Zoom, Bounce, Slide, Flip
        position='top-right'
        autoClose={5000}
        newestOnTop={true}
        theme='colored'
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName='figtree-toast-container'
        hideProgressBar
        closeButton={false}
      />
    </>
  )
}

export default Style
