import { getBaseUrlFor } from 'utils/Url'

const ROUTES = {
  // SEO
  SITEMAP: '/site-map', // Note: This is a UI route. Goto /sitemap.xml for the actual sitemap xml.
  // Dev
  GRID_OVERLAY: '/grid-overlay',

  // PUBLIC ROUTES
  ANY: '*', // This is a catch all route
  ROOT: '/',
  ERROR: '/404',
  EXPLORE: '/explore',
  LOG_IN: '/log-in',
  LOG_OUT: '/log-out',
  SIGN_UP: '/sign-up',
  HELP: 'help',
  HOSTING: {
    HOST_YOUR_EVENT: '/hosting/host-your-event',
    SELL_TICKETS_ONLINE: '/hosting/sell-online',
    HOST_RESPONSIBLY: '/hosting/host-responsibly',
    HOSTING_RESOURCES: '/hosting/hosting-resources',
    EVENT_PLANNING: '/hosting/event-planning',
    FOR_HOSTS: '/hosting/for-hosts',
  },
  FEATURES: {
    TICKETING: getBaseUrlFor('partners') + '/features/ticketing',
    ATTENDEE_MANAGEMENT: getBaseUrlFor('partners') + '/features/attendee-management',
    GROWTH_AND_MARKETING: getBaseUrlFor('partners') + '/features/growth-and-marketing',
    PRICING_COMPARISON: getBaseUrlFor('partners') + '/features/pricing',
  },
  SESSAMI: {
    ABOUT_US: '/about',
    CONTACT: '/contact',
    CAREERS: '/careers',
    INVESTORS: '/investors',
  },
  RESOURCES: {
    BLOG: '/blogs',
    GUIDES: '/guides',
    CASE_STUDIES: '/case-studies',
    FAQS: '/faqs',
    NEWS_CENTER: '/news-center',
    HELP: '/help',
  },
  LEGAL: {
    PRIVACY_POLICY: '/legal/privacy-policy',
    TERMS_OF_USE: '/legal/terms-of-use',
    BILLING: '/legal/billing',
    COOKIE_POLICY: '/legal/cookie-policy',
    BADGE_VERIFICATION_REQUIREMENTS: '/legal/badge-verification-requirements',
  },

  ONBOARDING: '/h/onboarding',
  FORGOT_PASSWORD: '/forgot-password',
  HOSTS_LANDING: '/hosts',
  FOR_GUESTS: '/for-guests',
  CONTACT: '/contact',
  GET_THE_APP: '/get-the-app',
  ABOUT_US: '/about',
  PRICING: '/pricing',
  FAQS: '/faqs',
  FEEDBACK: '/u/feedback',

  // PSEUDO PRIVATE ROUTES
  BOOKING_WIDGET: '/hosts/:hostId',
  SIGNAGE: '/hosts/:hostId/signage',
  // EVENT_BOOKING: '/events/:eventId',
  EVENT_BOOKING: '/events/:slug',
  EVENT_TYPE: '/events/type/:type',
  EVENT_CATEGORY: '/events/category/:category',
  USER_DETAIL: '/user/:userId',

  // PRIVATE ROUTES

  // User Authenticated Routes
  USER_DASHBOARD: '/u/dashboard',
  USER_TICKETS: '/u/tickets',
  USER_TICKET_DETAIL: '/u/tickets/:ticketId',
  USER_FEED: '/u/feed',

  // Settings
  USER_PROFILE: '/u/settings/profile',
  USER_SETTINGS: '/u/settings/security',
  USER_CUSTOMER_SUPPORT: '/u/customer-support',
  USER_DELETE_ACCOUNT: '/u/delete-account',
  USER_MARKETING_PERMS: '/u/marketing-permissions',
  USER_FEEDBACK: '/u/feedback',
  USER_EVENT_DETAILS_GUEST_DISPLAY: '/u/display-guests',

  // Host Authenticated Routes
  HOST_DASHBOARD: '/h/dashboard',
  HOST_SCAN: '/h/scan',
  HOST_GUESTS: '/h/guests',
  HOST_WAITLISTS: '/h/waitlists',
  HOST_GUESTS_ADD: '/h/guests/addguest',
  HOST_GUESTS_EDIT: '/h/guests/edit/:guestId',
  HOST_TICKETS: '/h/tickets',
  HOST_ONLINE_PROFILE: '/h/onlineprofile',

  // HOST AUTHENTICATED- Events
  HOST_EVENTS: '/h/events',
  HOST_EVENT_NEW: '/h/events/new',
  HOST_EVENT_EDIT: '/h/events/:eventId/edit',
  HOST_EVENT_OVERVIEW: '/h/events/:eventId/overview',
  HOST_EVENT_TICKETS: '/h/events/:eventId/tickets',
  HOST_EVENT_SURVEY: '/h/events/:eventId/survey',
  HOST_EVENT_SCAN: '/h/events/:eventId/scan',
  HOST_EVENT_SCANNERS: '/h/events/:eventId/scanners',
  HOST_EVENT_GUESTS: '/h/events/:eventId/guests',
  HOST_EVENT_WAITLIST: '/h/events/:eventId/waitlist',
  HOST_EVENT_SHARE: '/h/events/:eventId/share',

  // HOST - Venues
  HOST_VENUES: '/h/venues',
  HOST_VENUE_ADD: '/h/venues/add',
  HOST_VENUE_OVERVIEW: '/h/venues/:venueId/overview',
  HOST_VENUE_SEATPLAN: '/h/venues/:venueId/seatplan',

  // HOST - Reports
  HOST_REPORTS: '/h/reports',
  HOST_REPORTS_OVERVIEW: '/h/reports/overview',
  HOST_REPORTS_ORDERS: '/h/reports/orders',

  // HOST - Promotions
  HOST_PROMOTE: '/h/promote',
  HOST_EMAIL: '/h/marketing/email',
  HOST_EMAIL_RECIPIENTS: '/h/marketing/email/recipients',
  HOST_SHARE: '/h/promote/:hostId/share',
  HOST_EMAIL_CAMPAIGN: '/h/promote/email-campaign',

  // HOST - Settings
  HOST_SETTINGS: '/h/settings',
  HOST_SETTINGS_PROFILE: '/h/settings/profile',
  HOST_SETTINGS_PAYOUTS: '/h/settings/payouts',
  HOST_SETTINGS_COLORS: '/h/settings/colors',
  HOST_SETTINGS_PERMISSIONS: '/h/settings/permissions',

  //Guests
  GUESTS: '/guests',
  GUESTS_EDIT: '/guests/edit/:id',

  //Blogs
  BLOG_DETAILS: '/blogs/:blogId',
}

export default ROUTES
