import { CF_BASE, CLOUD_FUNCTIONS } from 'consts/CloudFunctions'
import HEADER from 'consts/Header'
import MSG from 'consts/Messages'
import ROUTES from 'consts/Routes'

// export const EVENT_PLACEHOLDER_IMAGE = '/assets/placeholder/sessami.png'
export const EVENT_PLACEHOLDER_IMAGE =
  'https://firebasestorage.googleapis.com/v0/b/sessami-production.appspot.com/o/gallery%2Ffbde527c-c25a-4b45-bf9b-83e9dd76ba72?alt=media&token=fa408e2d-c276-459f-996c-5d5bd59e298a'

export const USER_PLACEHOLDER_IMAGE =
  'https://firebasestorage.googleapis.com/v0/b/sessami-production.appspot.com/o/gallery%2F10f1d735-e6c3-479f-8f46-39004fc80454?alt=media&token=ec2ce479-23da-4107-b397-271f541ce824'

export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_FORMAT_SHORT = 'DD/MM'
export const DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm A'
export const DATE_TIME_SEO_FORMAT = 'YYYY-MM-DDTHH:mm-ss' // suggested seo format '2025-07-21T19:00-05:00'
export const HUMAN_FRIENDLY_DATE_TIME_FORMAT = 'ddd, MMM D, hh:mm A'
export const HUMAN_FRIENDLY_DATE_TIME_FORMAT_SHORT = 'MMM D, hh:mm A'
export const HUMAN_FRIENDLY_DATE_FORMAT = 'ddd, MMM D, YYYY'
export const MONTH_DAY_FORMAT = 'MMM DD'
export const DAY_DATE_FORMAT = 'MMM D ddd'
export const PRECISE_TIME = 'DD/MM/YYYY hh:mm:ss A'
export const TIME_FORMAT = 'hh:mm A'
export const EVENT_CARD_DATE_FORMAT = 'MMM D'
export const HUMAN_FRIENDLY_DTF_SHORT = 'ddd, MMM D'

export const UNVERIFIED_EMAIL = 'Please take a moment to verify your email address.'

export const GOOGLE_MAP_LIBRARIES = ['places']

export const MAX_ALLOWED_TICKETS = 50
export const MAX_CHECKOUT_DURATION_MINUTES = 5
export const MAX_IMAGES_PER_EVENT = 6
export const MAX_IMAGE_SIZE = 10 * 1024 * 1024 // 10MB

export const APP_UI_PLATFORMS = ['android', 'iphone', 'ipad', 'ipod']
export const APP_UI_ROUTES = [ROUTES.LOG_IN, ROUTES.SIGN_UP, ROUTES.FORGOT_PASSWORD]

export const TICKET_PROCESSING_PERCENTAGE = 6 // 6%
export const TICKET_PROCESSING_FIXED = 0.2 // GBP

export const EURO_COUNTRIES = [
  'IT',
  'FR',
  'DE',
  'ES',
  'PT',
  'GR',
  'IE',
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'HU',
  'HR',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'RO',
  'SE',
  'SI',
  'SK',
]

// Countries whose currencies are supported
// USD & EURO only
export const SUPPORTED_COUNTRIES = ['US', ...EURO_COUNTRIES]

export const REGEX = {
  EMAIL_VALIDATION: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, // i make this case insensitive
}

export const MAJORITY_SOLD_THRESHOLD = 0.5

export { ROUTES, CF_BASE, CLOUD_FUNCTIONS, MSG, HEADER }
