import cn from 'clsx'
import React from 'react'

// Template component to provide Navigation Bar and Top Bar to the protected pages
const ZenLayout = ({ children }) => {
  return (
    <div className={cn('relative')}>
      <main id='AppMain' className='flex flex-col space-y-4'>
        {children}
      </main>
    </div>
  )
}

export default ZenLayout
