import { MAJORITY_SOLD_THRESHOLD } from 'consts/index'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { setBasketStore } from 'store/reducers'
import { getDayjsObj, isBetweenTwoDates } from 'utils/DateTime'

dayjs.extend(isToday)

// #############################################################################
// #                           FILTER EVENTS BY TIME                           #
// #############################################################################

export const filterEventsByTime = (events, filter) => {
  switch (filter) {
    case 'all':
      return (
        [...events].sort((a, b) => {
          return new Date(getDayjsObj(b.date)).getTime() - new Date(getDayjsObj(a.date)).getTime()
        }) || []
      )

    case 'past':
      return events
        ?.filter(i =>
          dayjs((i?.date?.seconds || i?.date?._seconds) * 1000).isBefore(dayjs().startOf('day'))
        )
        .sort((a, b) => {
          return new Date(getDayjsObj(b.date)).getTime() - new Date(getDayjsObj(a.date)).getTime()
        })

    case 'today':
      return events?.filter(i => dayjs((i?.date?.seconds || i?.date?._seconds) * 1000).isToday())

    case 'future':
      return events
        ?.filter(i =>
          dayjs((i?.date?.seconds || i?.date?._seconds) * 1000).isAfter(dayjs().endOf('day'))
        )
        .sort((a, b) => {
          return new Date(getDayjsObj(a.date)).getTime() - new Date(getDayjsObj(b.date)).getTime()
        })
    // .sort((a, b) => {
    //   return new Date(getDayjsObj(b.date)).getTime() - new Date(getDayjsObj(a.date)).getTime()
    // })

    default:
      return events
  }
}

// #############################################################################
// #                           FILTER EVENTS BY STATUS                         #
// #############################################################################
export const filterEventsByStatus = (events, statusKey, value = true) => {
  return events?.filter(i => i[statusKey] === value)
}

export const getScannerOnlyEvents = (allEvents, userEmail) => {
  return allEvents
    .filter(i => i?.scanners?.filter(j => j?.email === userEmail).length > 0)
    .map(i => ({ ...i, scannerEvent: true }))
}

export const shouldPreventPurchase = (startDateTime, endDateTime) => {
  const endMs = (endDateTime?._seconds ?? endDateTime?.seconds) * 1000
  const startMs = (startDateTime?._seconds ?? startDateTime?.seconds) * 1000
  const cutOffTime = endMs || dayjs(startMs).endOf('day')

  return dayjs().isAfter(dayjs(cutOffTime))
}

export const shouldHideWaitlist = (startDateTime, endDateTime) => {
  const now = dayjs()

  if (endDateTime) {
    const eventEndDateTime = getDayjsObj(endDateTime)

    return eventEndDateTime.isBefore(now)
  } else {
    const eventStartDateTime = getDayjsObj(startDateTime)
    const startDatePlus24Hours = eventStartDateTime.add(1, 'day')

    return startDatePlus24Hours.isBefore(now)
  }
}

// #############################################################################
// #                           EVENT STATUS CODE                               #
// #############################################################################

export const isEventHappeningToday = date => {
  const today = dayjs().startOf('day')
  const ms = (date?._seconds ?? date?.seconds) * 1000
  const eventDate = dayjs(ms).startOf('day')

  return dayjs(eventDate).isSame(today, 'day', 'includeTime')
}

// Event has tickets but no capacity specified
export const isEventSoldOut = (event, totalTickets, totalTicketsSold, venueCapacity) => {
  // This feature is still in Beta and isn't actively developed
  // Might be removed in the future
  const venueSeatingArrangementUsed = totalTickets === 0 && event?.tickets?.length > 0

  return venueSeatingArrangementUsed
    ? totalTicketsSold >= venueCapacity
    : totalTicketsSold >= totalTickets
}

export const getAvailableTickets = tickets => {
  if (!tickets) return []

  return tickets?.filter(t => parseInt(t.sold) < parseInt(t.capacity))
}

// Check if less than 10% tickets are remaining
export const isMajoritySoldOut = (event, totalTickets, totalTicketsSold, venueCapacity) => {
  // This feature is still in Beta and isn't actively developed
  // Might be removed in the future
  const venueSeatingArrangementUsed = totalTickets === 0 && event?.tickets?.length > 0
  const maxCapacity = venueSeatingArrangementUsed ? venueCapacity : totalTickets

  return totalTicketsSold > MAJORITY_SOLD_THRESHOLD * maxCapacity
}

export const hasSomeSoldOutTickets = tickets => {
  return tickets.some(ticket => {
    const capacity = parseInt(ticket?.capacity)
    const remainingTickets = capacity - ticket?.sold
    return remainingTickets <= 0
  })
}

export const getTotalEventTickets = tickets => {
  return tickets?.reduce((total, i) => parseInt(i?.capacity) + total, 0) || 0
}

export const getTotalEventTicketsSold = tickets => {
  return tickets?.reduce((total, i) => parseInt(i?.sold) + total, 0) || 0
}

export const getHostTags = events => {
  const tags = events?.map(event => {
    return event?.tags?.map(tag => {
      return tag
    })
  })

  return tags.flat()
}

export const getHostCategory = events => {
  const uniqueCategories = events
    .map(event => event?.category)
    .filter(cat => typeof cat === 'string' && cat.trim() !== '')
    .reduce((unique, cat) => {
      if (!unique.includes(cat)) {
        unique.push(cat)
      }
      return unique
    }, [])

  return uniqueCategories
}

export const getHostEventTypes = events => {
  const uniqueEventTypes = events
    .map(event => event?.eventType)
    .filter(type => typeof type === 'string' && type.trim() !== '')
    .reduce((unique, type) => {
      if (!unique.includes(type)) {
        unique.push(type)
      }
      return unique
    }, [])

  return uniqueEventTypes
}

export const getEventsByEventType = (events, eventType) => {
  if (!events) return []

  const sortedEvents =
    [...events].sort((a, b) => {
      return new Date(getDayjsObj(a?.date)).getTime() - new Date(getDayjsObj(b?.date)).getTime()
    }) || []

  if (eventType === 'All') return sortedEvents

  return sortedEvents.filter(event => event.eventType === eventType)
}

/**
 * Checks if the tickets are available and flushes the cart if needed
 * @param {*} tickets
 * @param {*} cart
 * @param {*} dispatch
 */
export const checkTicketAvailabilityAndFlush = (
  tickets,
  cart,
  dispatch,
  pvtTixQueryParams = []
) => {
  if (!cart?.length || !tickets?.length) return

  let needsFlushing = false

  const mutableCart = cart.map(i => {
    let updatedTicket = { ...i }

    // Check - Ticket's Validity as a whole
    // LTI - Latest Ticket Information (From Backend/host)
    const LTI = tickets?.find(t => t?.id === i?.id)
    if (!LTI) return {}

    // Check - Remaining Ticket Count
    let remainingTickets = Math.max(0, parseInt(LTI?.capacity) - LTI?.sold)
    if (i?.count > remainingTickets) {
      updatedTicket.count = remainingTickets
      needsFlushing = true
    }

    // Check - Validity of Special Tickets
    switch (i?.type) {
      case 'private':
        // Check - Validity of Query Params
        if (!pvtTixQueryParams.includes(i?.id)) {
          updatedTicket.count = 0
          needsFlushing = true
        }

        break

      case 'scheduledVisible':
      case 'scheduledHidden':
        // Check - Validity of Range
        if (!isBetweenTwoDates(LTI?.startDate, LTI?.endDate)) {
          updatedTicket.count = 0
          needsFlushing = true
        }

        break

      case 'regular':
      case 'normal':
      default:
      // nothing special to check really here for regular tickets
    }

    return updatedTicket
  })

  if (needsFlushing) dispatch(setBasketStore(mutableCart))
}

// Helpful for checking if all event categories are empty (BrowseByCategory)
export const areAllCategoryCountsZero = counts => {
  return Object.values(counts).every(count => count === 0)
}
