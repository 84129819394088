import { ROUTES } from 'consts'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getUserByEmail, getUserByIdwRTU } from 'services/client/buyer'
import { setUserAuth, setUserInfo } from 'store/reducers'
import setCookie from 'utils/SetCookie'

import useGlobalLoader from './useGlobalLoader'

export default function useLogIn() {
  const dispatch = useDispatch()
  const router = useRouter()
  const basketStore = useSelector(s => s?.pr?.ui?.basketStore)
  const authenticatedUser = useSelector(s => s?.pr?.user.auth)

  const { loading } = useGlobalLoader()

  const fetchUserInfo = async () => {
    console.log('@fetchUserInfo')
    if (!authenticatedUser.uid) return

    getUserByIdwRTU(authenticatedUser.uid, user => {
      dispatch(setUserInfo(user))
      loading.remove()
    })
  }

  const logInHandler = async authenticatedCurrentUser => {
    loading.start()
    dispatch(setUserAuth(authenticatedCurrentUser))

    // Set cookie in order to keep track of auth state for route protection
    setCookie('currentUser', authenticatedCurrentUser.uid)

    const user = await getUserByEmail(authenticatedCurrentUser?.email)

    const redirectUrl = router?.query?.redirect

    if (redirectUrl) {
      router.push(redirectUrl)
    }

    // Basketstore is localize to one event only at the moment
    else if (basketStore?.[0]?.slug) {
      router.push(ROUTES.EVENT_BOOKING.replace(':slug', basketStore?.[0]?.slug))

      // Kick user to their dashboard from login page.
    } else {
      if (user?.userType === 'host') {
        toast.info('Hosting has moved to partners.sessami.co!')
      }
      router.replace(ROUTES.USER_DASHBOARD)
    }
  }

  return { logInHandler, fetchUserInfo }
}
