// import { CiCalendar } from '@react-icons/all-files/ci/CiCalendar'
import { GoDotFill } from '@react-icons/all-files/go/GoDotFill'
import cn from 'clsx'
import Image from 'components/atoms/Image'
import EventSocialProofBadge from 'components/molecules/EventSocialProofBadge'
import HostsActiononEvents from 'components/molecules/HostsActiononEvents'
import TicketPrice from 'components/molecules/TicketPrice'
import { EVENT_CARD_DATE_FORMAT, ROUTES } from 'consts'
// import Icons from 'consts/Icons'
import dayjs from 'dayjs'
import Link from 'next/link'
import React from 'react'
// import { TiLocationArrow } from '@react-icons/all-files/ti/TiLocationArrow'
import { useSelector } from 'react-redux'
import { readSellerById } from 'services/client/seller'
import useSWR from 'swr'
import { durationBetween, formatDate, formatTime } from 'utils/DateTime'

// import { checkIsStringAHtml, sliceHtml } from 'utils/String'

const EventCard = ({
  event,
  showPrice = true,
  target = '_self',
  isMinimal = false,
  onClick = () => {},
  isFromHost = false,
}) => {
  const MAX_EVENT_CARD_IMAGES_DESKTOP = 1

  const { data: host } = useSWR(event?.hostId, () => readSellerById(event?.hostId))
  const isOwnerOfEvent = useSelector(s => s?.pr?.user?.auth?.uid === event?.hostId)

  // const isDescriptionHtml = checkIsStringAHtml(event?.description)

  return (
    <Link
      key={event?.eventId}
      target={target}
      href={`${isFromHost ? ROUTES.HOST_EVENT_OVERVIEW.replace(':eventId', event?.eventId) : ROUTES.EVENT_BOOKING.replace(':slug', event?.slug)}`}
      onClick={() => onClick(event?.venue?.coords?.lat, event?.venue?.coords?.lng)}
      className={cn(
        'custom-transition-b group relative',
        isMinimal
          ? 'flex cursor-pointer items-center justify-center gap-2 rounded-sm border-b px-4 py-4 first:border-t hover:bg-base-200'
          : 'col-span-2 rounded-2xl lg:col-span-3'
      )}>
      <figure
        className={cn(
          'relative overflow-hidden rounded-xl',
          isMinimal ? 'aspect-video flex-[0.8]' : 'aspect-[16/10]'
        )}>
        {event?.images?.slice(0, MAX_EVENT_CARD_IMAGES_DESKTOP).map((image, index) => (
          <Image
            key={image?.url + index}
            src={image?.url}
            alt='Event Card'
            layout='fill'
            objectFit='cover'
            placeholder='blur'
            loading='lazy'
            sizing={640}
            className={cn(isMinimal ? '' : 'custom-transition-a group-hover:scale-105')}
          />
        ))}

        <HostsActiononEvents isMinimal={isMinimal} isOwnerOfEvent={isOwnerOfEvent} event={event} />
      </figure>

      {isMinimal ? null : <EventSocialProofBadge event={event} />}

      <div
        className={cn(
          'flex flex-row items-start gap-2 md:gap-3',
          isMinimal && 'flex flex-[1.5] flex-col gap-2 truncate'
        )}>
        {host?.profileImage && !isMinimal && (
          <figure className='relative mt-2 hidden aspect-square flex-1 overflow-hidden rounded-full md:block'>
            <Image src={host?.profileImage} alt='Event icon' fill objectFit='cover' sizing={160} />
          </figure>
        )}

        <section className={cn('flex flex-[7] flex-col gap-1')}>
          <h2
            className={cn(
              isMinimal
                ? 'text-md overflow-hidden text-ellipsis font-medium'
                : 'mt-2 text-sm md:text-base lg:text-lg'
            )}>
            {event?.name}
          </h2>

          <div
            className={cn(
              'flex flex-row flex-wrap gap-2 text-primary/75',
              isMinimal ? 'text-sm' : 'text-sm md:text-base lg:text-lg'
            )}>
            <div className='flex flex-row items-center gap-2'>
              <TicketPrice
                tickets={event?.tickets}
                showPrice={showPrice}
                isMinimal={isMinimal}
                currency={event?.currency}
              />
            </div>

            <p className={cn('flex flex-row items-center', isMinimal ? 'gap-1' : 'gap-2')}>
              <GoDotFill size={8} />
              <time className={cn('block', isMinimal ? 'overflow-hidden text-ellipsis' : '')}>
                {dayjs(event?.date?.seconds * 1000).isToday()
                  ? `Today at ${formatTime(event?.date)}`
                  : `${formatDate(event?.date, EVENT_CARD_DATE_FORMAT)}`}
              </time>
              <p className='hidden italic text-secondary md:block'>
                ({durationBetween(event?.date)})
              </p>
            </p>
          </div>

          {/* {isMinimal && (
            <p className='hidden md:block text-primary text-sm opacity-75'>
              {isDescriptionHtml ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: sliceHtml(event?.description, 40),
                  }}
                />
              ) : (
                <span>{event?.description?.slice(0, 40)}</span>
              )}
            </p>
          )} */}

          {event?.venue?.formattedAddress && isMinimal && (
            <div className={cn('flex flex-row items-center', isMinimal ? 'gap-1' : 'gap-2')}>
              {/* <TiLocationArrow size={24} /> */}
              <address
                className={cn(
                  'block text-sm font-normal text-primary/75',
                  isMinimal ? 'overflow-hidden text-ellipsis' : ''
                )}>
                {event?.venue?.formattedAddress.split(',')[0]}
              </address>
            </div>
          )}
        </section>
      </div>
    </Link>
  )
}

export default EventCard
